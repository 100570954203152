/* eslint-disable no-empty-pattern */
import { removeObjectFromArray } from '~/utils/arrayUtils';
import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

const namespace = 'organizations';
export const SET_CURRENT_ORG_ID = `${namespace}/setUserOrgId`;
export const SET_ORG_BRANCHES = `${namespace}/setOrgBranches`;
export const SET_ORG_USERS = `${namespace}/setOrgUsers`;
export const SET_TOTAL_ORG_USERS = `${namespace}/setTotalOrgUsers`;
export const DELETE_ORG_USER = `${namespace}/deleteOrgUser`;
export const SET_ADD_USER_STATUS = `${namespace}/setAddUserStatus`;
export const SET_ORG_LOGISTICS = `${namespace}/setOrgLogistics`;
export const SET_TOTAL_ORG_LOGISTICS = `${namespace}/setTotalOrgLogistics`;
export const SET_ORG_LOCATIONS = `${namespace}/setOrgLocations`;
export const SET_TOTAL_ORG_LOCATIONS = `${namespace}/setTotalOrgLocations`;
export const SET_USER_ERROR_PAGE = `${namespace}/setuserPageError`;
export const CLEAR_ORG_ID = `${namespace}/clearOrgId`;

export const namespaced = false;

export const state = () => ({
  currentOrgId: null,
  orgBranches: null,
  orgUsers: [],
  totalOrgUsers: 0,
  successfullAddUser: null,
  isUserErrorPage: false,
  orgLogistics: [],
  totalOrgLogistics: 0,
  orgLocations: [],
  totalOrgLocations: 0,
});

export const mutations = {
  [SET_CURRENT_ORG_ID](state, orgId) {
    state.currentOrgId = orgId;
  },
  [SET_ORG_BRANCHES](state, orgBranches) {
    state.orgBranches = orgBranches;
  },
  [SET_ORG_USERS](state, orgUsers) {
    state.orgUsers = orgUsers;
  },
  [SET_TOTAL_ORG_USERS](state, totalOrgUsers) {
    state.totalOrgUsers = totalOrgUsers;
  },
  [DELETE_ORG_USER](state, userId) {
    removeObjectFromArray({ array: state.orgUsers, itemKey: 'user_id', itemId: userId });
  },
  [SET_ADD_USER_STATUS](state, { statusCode, email }) {
    state.successfullAddUser = {
      statusCode,
      email,
    };
  },
  [SET_USER_ERROR_PAGE](state, isError) {
    state.isUserErrorPage = isError;
  },
  [SET_ORG_LOGISTICS](state, orgLogistics) {
    state.orgLogistics = orgLogistics;
  },
  [SET_TOTAL_ORG_LOGISTICS](state, totalOrgLogistics) {
    state.totalOrgLogistics = totalOrgLogistics;
  },
  [SET_ORG_LOCATIONS](state, orgLocations) {
    state.orgLocations = orgLocations;
  },
  [SET_TOTAL_ORG_LOCATIONS](state, totalOrgLocations) {
    state.totalOrgLocations = totalOrgLocations;
  },
  [CLEAR_ORG_ID](state) {
    state.currentOrgId = null;
  },
};

export const getters = {
  currentOrgId: (state) => {
    return state.currentOrgId;
  },
  currentOrgBranches: (state) => {
    return state.orgBranches;
  },
  users: (state) => {
    return state.orgUsers;
  },
  totalUsers: (state) => {
    return state.totalOrgUsers;
  },
  successfullAddUser: (state) => {
    return state.successfullAddUser;
  },
  isErrorPage: (state) => {
    return state?.isUserErrorPage ?? false;
  },
  orgLogisticsByOrganization: (state) => {
    return state.orgLogistics;
  },
  totalOrgLogisticsByOrganization: (state) => {
    return state.totalOrgLogistics;
  },
};

export const actions = {
  setCurrentOrgId({ commit }, orgId) {
    commit(SET_CURRENT_ORG_ID, orgId);
  },
  async getOrgBranches({ rootGetters, commit }) {
    try {
      const orgId = rootGetters.currentOrgId;
      const { data } = await this.$axios.get(`/api/fleet/accounts/organizations/${orgId}/branches`);
      commit(SET_ORG_BRANCHES, data);
    } catch (err) {
      // TODO: Handle the BE errors in a more generic way
      if (err?.response?.status === 403) {
        commit(SET_USER_ERROR_PAGE, true);
      }
    }
  },
  async getOrgUsers({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    const orgId = rootGetters.currentOrgId;
    try {
      const { data } = await this.$axios.get(`api/fleet/accounts/organizations/${orgId}/users`, {
        params: {
          page,
          limit,
        },
      });
      commit(SET_ORG_USERS, data.items);
      commit(SET_TOTAL_ORG_USERS, data.pagination.total_items);
      commit(SET_USER_ERROR_PAGE, false);
    } catch (err) {
      if (err?.response?.status === 403) {
        commit(SET_USER_ERROR_PAGE, true);
      } else {
        const message = this.app.i18n.t('something_went_wrong_txt');
        this.$toast.global.customError(message);
      }
    }
  },
  async createOrgUser({ rootGetters, commit }, formData) {
    try {
      const orgId = rootGetters.currentOrgId;
      const userPayload = {
        email: formData?.email ?? '',
        first_name: formData?.firstName ?? '',
        last_name: formData?.lastName ?? '',
        locale: formData.locale,
        ...(!formData.isAdminChecked && { branches: formData.branches }),
      };
      const response = await this.$axios.post(
        `/api/fleet/accounts/organizations/${orgId}/users?source=${process.env.sourceTag}`,
        userPayload,
      );
      commit(SET_ADD_USER_STATUS, { statusCode: response.status, email: userPayload.email });
      return response;
    } catch (err) {
      if (err?.response?.status === 403) {
        commit(SET_USER_ERROR_PAGE, true);
      } else if (err?.response?.status === 409) {
        const message = this.app.i18n.t('user_already_belongs_txt');
        this.$toast.global.customError(message);
      } else {
        const message = this.app.i18n.t('something_went_wrong_txt');
        this.$toast.global.customError(message);
      }
    }
  },
  async updateOrgUser({ rootGetters }, { userId, form: formData }) {
    try {
      const orgId = rootGetters.currentOrgId;
      const response = await this.$axios.put(`/api/fleet/accounts/organizations/${orgId}/users/${userId}`, formData);
      return response;
    } catch (err) {
      // console.log(err);
    }
  },
  async deleteOrgUser({ commit, rootGetters }, userId) {
    const orgId = rootGetters.currentOrgId;
    try {
      await this.$axios.delete(`api/fleet/accounts/organizations/${orgId}/users/${userId}`);
      commit(DELETE_ORG_USER, userId);
    } catch (err) {
      if (err?.response?.status === 403) {
        commit(SET_USER_ERROR_PAGE, true);
      }
    }
  },
  async getOrgLogisticsByOrganization({ rootGetters, commit }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    try {
      const orgId = rootGetters.currentOrgId;
      const { data } = await this.$axios.get(`/api/fleet/accounts/organizations/${orgId}/logistics`, {
        params: {
          page,
          limit,
        },
      });
      commit(SET_ORG_LOGISTICS, data.items);
      commit(SET_TOTAL_ORG_LOGISTICS, data.pagination.total_items);
    } catch (err) {
      const message = this.app.i18n.t('something_went_wrong_txt');
      this.$toast.global.customError(message);
    }
  },
  async createOrgLogistics({ rootGetters }, formData) {
    try {
      const orgId = rootGetters.currentOrgId;
      const response = await this.$axios.post(`/api/fleet/accounts/organizations/${orgId}/logistics`, formData);
      return response;
    } catch (err) {
      const message = this.app.i18n.t('something_went_wrong_txt');
      this.$toast.global.customError(message);
    }
  },
};
