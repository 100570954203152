const removeObjectFromArray = ({ array, itemKey, itemId }) => {
  const idx = array.findIndex((object) => object[itemKey] === itemId);
  if (idx !== -1) {
    array.splice(idx, 1);
  }
  return array;
};
const getPercentageBetweenNewAndOld = ({ newValue, oldValue }) => {
  return Math.trunc(((newValue - oldValue) / ((newValue + oldValue) / 2)) * 100);
};
export { removeObjectFromArray, getPercentageBetweenNewAndOld };
