export const OrderIssueTypes = [
  {
    label: 'issue_type_stream_1',
    value: 'stream_issue_1',
  },
  {
    label: 'issue_type_stream_2',
    value: 'stream_issue_2',
  },
  {
    label: 'issue_type_location_1',
    value: 'location_issue_1',
  },
  {
    label: 'issue_type_location_2',
    value: 'location_issue_2',
  },
  {
    label: 'issue_type_container_1',
    value: 'container_issue_1',
  },
  {
    label: 'issue_type_container_2',
    value: 'container_issue_2',
  },
  {
    label: 'issue_type_container_3',
    value: 'container_issue_3',
  },
  {
    label: 'issue_type_container_4',
    value: 'container_issue_4',
  },
  {
    label: 'issue_type_container_5',
    value: 'container_issue_5',
  },
  {
    label: 'issue_type_container_6',
    value: 'container_issue_6',
  },
  {
    label: 'issue_type_other',
    value: 'other',
  },
  {
    label: 'issue_type_pickup_1',
    value: 'pickup_issue_1',
  },
  {
    label: 'issue_type_delivery_5',
    value: 'delivery_issue_5',
  },
];
