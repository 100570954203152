import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6b77f056&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=6b77f056&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheSidebar: require('/home/runner/work/seenons-fe-logistics/seenons-fe-logistics/apps/logistics-manager/components/layout/sidebar/TheSidebar.vue').default,TheTopNavBar: require('/home/runner/work/seenons-fe-logistics/seenons-fe-logistics/apps/logistics-manager/components/layout/top-navbard/TheTopNavBar.vue').default})
