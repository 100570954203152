
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrgUsersDropdown',
  data() {
    return {
      currentOrgIndex: 0, // TODO: check if we make it persistent for the user page
    };
  },
  computed: {
    ...mapGetters(['userAdminOrganizations', 'currentOrgId']),
    orgsNamesItems() {
      return this.userAdminOrganizations.map((org, index) => ({
        id: index,
        name: org.organization_name,
      }));
    },
  },
  created() {
    const [firstOrganization] = this.userAdminOrganizations;
    const defaultOrganizationId = firstOrganization?.organization_id;
    if (this.currentOrgId) {
      this.currentOrgIndex = this.userAdminOrganizations.findIndex((org) => org.organization_id === this.currentOrgId);
    }
    if (this.currentOrgId === null && defaultOrganizationId) {
      this.setCurrentOrgId(defaultOrganizationId);
    }
  },
  methods: {
    ...mapActions(['setCurrentOrgId']),
    selectedOrganization(index) {
      this.currentOrgIndex = index;
      this.setCurrentOrgId(this.userAdminOrganizations[index].organization_id);
    },
  },
};
