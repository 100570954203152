import { removeObjectFromArray } from '~/utils/arrayUtils';
import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

export const SET_ORG_LOGISTIC_GARAGES = 'setOrgLogisticGarages';
export const SET_TOTAL_ORG_LOGISTIC_GARAGES = 'setTotalOrgLogisticGarages';
export const UPDATE_ORG_LOGISTIC_GARAGE = 'updateOrgLogisticGarage';
export const DELETE_ORG_LOGISTIC_GARAGE = 'deleteOrgLogisticGarage';
export const namespaced = false;

export const state = () => ({
  orgLogisticGarages: [],
  totalOrgLogisticGarages: 0,
});

export const getters = {
  garages: (state) => {
    return state.orgLogisticGarages;
  },
  totalGarages: (state) => {
    return state.totalOrgLogisticGarages;
  },
};

export const mutations = {
  [SET_ORG_LOGISTIC_GARAGES](state, garages) {
    state.orgLogisticGarages = garages;
  },
  [SET_TOTAL_ORG_LOGISTIC_GARAGES](state, totalOrgLogisticGarages) {
    state.totalOrgLogisticGarages = totalOrgLogisticGarages;
  },
  [DELETE_ORG_LOGISTIC_GARAGE](state, garageId) {
    removeObjectFromArray({ array: state.orgLogisticGarages, itemKey: 'id', itemId: garageId });
  },
};

const utils = {
  buildGaragePayload(formData) {
    return {
      name: formData?.name ?? '',
      address: {
        street_name: formData?.address?.streetName ?? '',
        house_number: formData?.address?.houseNumber ?? '',
        postal_code: formData?.address?.postalCode ?? '',
        town: formData?.address?.town ?? '',
        country_code: formData?.address?.countryCode ?? '',
      },
      time_start: formData?.timeStart ?? '',
      time_end: formData?.timeEnd ?? '',
    };
  },
};

export const actions = {
  async getOrgLogisticGarages({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const { data } = await this.$axios.get(`api/fleet/logistics/${orgLogisticId}/garages`, {
      params: {
        page,
        limit,
      },
    });
    commit(SET_ORG_LOGISTIC_GARAGES, data.items);
    commit(SET_TOTAL_ORG_LOGISTIC_GARAGES, data.pagination.total_items);
  },
  async createOrgLogisticGarage({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.post(
      `api/fleet/logistics/${orgLogisticId}/garages`,
      utils.buildGaragePayload(formData),
    );
    return response;
  },
  async updateOrgLogisticGarage({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.put(
      `/api/fleet/logistics/${orgLogisticId}/garages/${formData.id}`,
      utils.buildGaragePayload(formData),
    );
  },
  async deleteOrgLogisticGarage({ commit, rootGetters }, garageId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.delete(`/api/fleet/logistics/${orgLogisticId}/garages/${garageId}`);
    commit(DELETE_ORG_LOGISTIC_GARAGE, garageId);
  },
};
