import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

export const namespaced = false;
export const SET_ORG_LOGISTIC_DRIVERS = 'setdrivers';
export const SET_DRIVER = 'setDriver';
export const SET_AVAILABLE_DRIVERS = 'setAvailableDrivers';
export const SET_TOTAL_DRIVERS = 'setTotalDrivers';

export const state = () => ({
  drivers: [],
  driver: {},
  availableDrivers: [],
  totalDrivers: null,
});
export const getters = {
  driver: (state) => {
    if (state.driver) {
      return state.driver;
    }
  },
  drivers: (state) => {
    if (state.drivers) {
      return state.drivers;
    }
  },
  availableDrivers: (state) => {
    return state.availableDrivers;
  },
  totalDrivers: (state) => {
    return state.totalDrivers;
  },
};

export const mutations = {
  [SET_DRIVER](state, driver) {
    state.driver = driver;
  },
  [SET_ORG_LOGISTIC_DRIVERS](state, drivers) {
    state.drivers = drivers;
  },
  [SET_AVAILABLE_DRIVERS](state, availableDrivers) {
    state.availableDrivers = availableDrivers;
  },
  [SET_TOTAL_DRIVERS](state, totalDrivers) {
    state.totalDrivers = totalDrivers;
  },
};

const utils = {
  buildDriverPayload(formData) {
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone_number: formData.phone_number,
      email: formData.email,
      license: {
        categories: formData.license_categories_type,
        expiration_date: formData.license_expiration_date,
        number: formData.license_number,
      },
    };
  },
};

export const actions = {
  async getAvailableDrivers({ commit, rootGetters }, availableOn) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const { data } = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/drivers/available/${availableOn}`);
    commit(SET_AVAILABLE_DRIVERS, data);
  },
  async getDriver({ commit, rootGetters }, driverId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const { data } = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/drivers/${driverId}`);
    commit(SET_DRIVER, data);
  },
  async getDrivers({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/drivers`, {
      params: {
        page,
        limit,
      },
    });
    commit(SET_ORG_LOGISTIC_DRIVERS, response.data.items);
    commit(SET_TOTAL_DRIVERS, response.data.pagination.total_items);
  },
  async createDriver({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.post(
      `/api/fleet/logistics/${orgLogisticId}/drivers`,
      utils.buildDriverPayload(formData),
    );
    return response;
  },
  async editDriver({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const driver_id = formData.driver_id;
    const response = await this.$axios.put(
      `/api/fleet/logistics/${orgLogisticId}/drivers/${driver_id}`,
      utils.buildDriverPayload(formData),
    );
    return response;
  },
  async activateDriver({ rootGetters }, driverId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.patch(`/api/fleet/logistics/${orgLogisticId}/drivers/${driverId}/activate`);
  },
  async deactivateDriver({ rootGetters }, driverId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.patch(`/api/fleet/logistics/${orgLogisticId}/drivers/${driverId}/deactivate`);
  },
};
