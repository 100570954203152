import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

const namespace = 'vehicles';
export const SET_VEHICLES = `${namespace}/SET_ALL`;
export const namespaced = false;
export const SET_VEHICLE = 'setVehicle';
export const SET_TOTAL_VEHICLES = 'setTotalVehicles';

export const state = () => ({
  vehicles: [],
  vehicle: {},
  totalVehicles: null,
});

export const mutations = {
  [SET_VEHICLES](state, vehicles) {
    state.vehicles = vehicles;
  },
  [SET_VEHICLE](state, vehicle) {
    state.vehicle = vehicle;
  },
  [SET_TOTAL_VEHICLES](state, totalVehicles) {
    state.totalVehicles = totalVehicles;
  },
};

export const getters = {
  vehicles: (state) => {
    if (state.vehicles) {
      return state.vehicles;
    }
  },
  vehicle: (state) => {
    if (state.vehicle) {
      return state.vehicle;
    }
  },
  totalVehicles: (state) => {
    return state.totalVehicles;
  },
};

const utils = {
  buildVehiclePayload(formData) {
    return {
      metadata: {
        vehicle_body_type: formData.vehicle_body_type,
        color: formData.color,
        ownership: formData.ownership,
        license_category_type: formData.license_category_type,
        brand: formData.brand,
        brand_model_type: formData.brand_model_type,
        year: formData.year,
        license_plate: formData.license_plate,
        fuel_type: formData.fuel_type,
        vin_id: formData.vin_id,
      },
      dimensions: {
        width: {
          value: formData.width,
          unit: 'm',
        },
        length: {
          value: formData.length,
          unit: 'm',
        },
        height: {
          value: formData.height,
          unit: 'm',
        },
      },
      capacity: {
        volume: {
          value: formData.volume,
          unit: 'm3',
        },
        weight: {
          value: formData.weight,
          unit: 'kg',
        },
      },
      max_distance: {
        value: formData.max_distance,
        unit: 'km',
      },
      image_urls: [],
      supported_stream_ids: formData.supported_stream_ids,
    };
  },
};

export const actions = {
  async getVehicles({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/vehicles`, {
      params: {
        page,
        limit,
      },
    });
    commit(SET_VEHICLES, response.data.items);
    commit(SET_TOTAL_VEHICLES, response.data.pagination.total_items);
  },
  async editVehicle({ rootGetters }, formData) {
    const org_logistic_id = rootGetters.currentOrgLogisticId;
    const vehicle_id = formData.vehicle_id;
    await this.$axios.put(
      `/api/fleet/logistics/${org_logistic_id}/vehicles/${vehicle_id}`,
      utils.buildVehiclePayload(formData),
    );
  },
  async createVehicle({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;

    await this.$axios.post(`/api/fleet/logistics/${orgLogisticId}/vehicles`, utils.buildVehiclePayload(formData));
  },
  async activateVehicle({ rootGetters }, vehicleId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.patch(`/api/fleet/logistics/${orgLogisticId}/vehicles/${vehicleId}/activate`);
  },
  async deactivateVehicle({ rootGetters }, vehicleId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.patch(`/api/fleet/logistics/${orgLogisticId}/vehicles/${vehicleId}/deactivate`);
  },
  async getVehicle({ commit, rootGetters }, vehicleId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;

    try {
      const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/vehicles/${vehicleId}`);
      commit(SET_VEHICLE, response.data);
      return response.data;
    } catch (err) {
      this.$toast.global.customError(`Something went wrong: ${err}`);
    }
  },
};
