import * as Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';

export default ({ store }) => {
  createPersistedState({
    key: 'persistent-storage',
    paths: ['orgLogistics.currentOrgLogistic', 'organizations.currentOrgId'],
    storage: {
      getItem: (key) => {
        return Cookies.get(key);
      },
      // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      setItem: (key, value) => Cookies.set(key, value, { expires: 2, secure: false }),
      removeItem: (key) => Cookies.remove(key),
    },
  })(store);
};
