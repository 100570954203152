import { removeObjectFromArray } from '~/utils/arrayUtils';
import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

export const SET_ORG_LOGISTIC_DEPOTS = 'setOrgLogisticDepots';
export const SET_TOTAL_ORG_LOGISTIC_DEPOTS = 'setTotalOrgLogisticDepots';
export const UPDATE_ORG_LOGISTIC_DEPOT = 'updateOrgLogisticDepot';
export const DELETE_ORG_LOGISTIC_DEPOT = 'deleteOrgLogisticDepot';
export const namespaced = false;

export const state = () => ({
  orgLogisticDepots: [],
  totalOrgLogisticDepots: 0,
});

export const getters = {
  depots: (state) => {
    return state.orgLogisticDepots;
  },
  totalDepots: (state) => {
    return state.totalOrgLogisticDepots;
  },
};

export const mutations = {
  [SET_ORG_LOGISTIC_DEPOTS](state, depots) {
    state.orgLogisticDepots = depots;
  },
  [SET_TOTAL_ORG_LOGISTIC_DEPOTS](state, totalOrgLogisticDepots) {
    state.totalOrgLogisticDepots = totalOrgLogisticDepots;
  },
  [DELETE_ORG_LOGISTIC_DEPOT](state, depotId) {
    removeObjectFromArray({ array: state.orgLogisticDepots, itemKey: 'id', itemId: depotId });
  },
};

const utils = {
  buildDepotPayload(formData) {
    return {
      name: formData?.name ?? '',
      phone_number: formData?.phoneNumber ?? '',
      address: {
        street_name: formData?.address?.streetName ?? '',
        house_number: formData?.address?.houseNumber ?? '',
        postal_code: formData?.address?.postalCode ?? '',
        town: formData?.address?.town ?? '',
        country_code: formData?.address?.countryCode ?? '',
      },
      time_start: formData?.timeStart ?? '',
      time_end: formData?.timeEnd ?? '',
    };
  },
};

export const actions = {
  async getOrgLogisticDepots({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const { data } = await this.$axios.get(`api/fleet/logistics/${orgLogisticId}/depots`, {
      params: {
        page,
        limit,
      },
    });
    commit(SET_ORG_LOGISTIC_DEPOTS, data.items);
    commit(SET_TOTAL_ORG_LOGISTIC_DEPOTS, data.pagination.total_items);
  },

  async createOrgLogisticDepot({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.post(
      `api/fleet/logistics/${orgLogisticId}/depots`,
      utils.buildDepotPayload(formData),
    );
    return response;
  },

  async updateOrgLogisticDepot({ rootGetters }, formData) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.put(
      `/api/fleet/logistics/${orgLogisticId}/depots/${formData.id}`,
      utils.buildDepotPayload(formData),
    );
  },

  async deleteOrgLogisticDepot({ commit, rootGetters }, depotId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    await this.$axios.delete(`/api/fleet/logistics/${orgLogisticId}/depots/${depotId}`);
    commit(DELETE_ORG_LOGISTIC_DEPOT, depotId);
  },
};
