export const FuelType = {
  1: 'fuel_type_none',
  2: 'fuel_type_electric',
  3: 'fuel_type_hybrid_diesel',
  4: 'fuel_type_hybrid_gasoline',
  5: 'fuel_type_hybrid_gas',
  6: 'fuel_type_diesel',
  7: 'fuel_type_gasoline',
  8: 'fuel_type_gas',
};
