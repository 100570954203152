export const Status = {
  '-1': 'status_temporary',
  '-99': 'status_deleted',
  0: 'status_new',
  1: 'status_pending',
  2: 'status_scheduled',
  3: 'status_in_progress',
  4: 'status_completed',
  5: 'status_delayed',
  6: 'status_cancelled',
  7: 'status_issue',
};
