import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

export const namespaced = false;
export const SET_ORG_LOGISTIC_DASHBOARD_ROUTES = 'setOrgRoutes';
export const SET_ORG_LOGISTIC_ROUTES = 'setOrgLogisticsRoutes';
export const SET_ORG_LOGISTIC_ROUTES_PAGINATION = 'setOrgLogisticRoutesPagination';
export const SET_TABLE_ROUTE = 'setTableRoute';

export const state = () => ({
  dashboardRoutes: [],
  orgLogisticRoutes: [],
  orgLogisticRoutesPagination: {},
  selectedDashRoute: null,
});

export const mutations = {
  [SET_ORG_LOGISTIC_DASHBOARD_ROUTES](state, dashboardRoutes) {
    state.dashboardRoutes = dashboardRoutes;
  },
  [SET_ORG_LOGISTIC_ROUTES](state, orgLogisticRoutes) {
    state.orgLogisticRoutes = orgLogisticRoutes;
  },
  [SET_TABLE_ROUTE](state, selectedDashRoute) {
    state.selectedDashRoute = selectedDashRoute;
  },
  [SET_ORG_LOGISTIC_ROUTES_PAGINATION](state, orgLogisticRoutesPagination) {
    state.orgLogisticRoutesPagination = orgLogisticRoutesPagination;
  },
};

export const getters = {
  selectedDashRoute: (state) => {
    return state.selectedDashRoute;
  },
  dashboardRoutes: (state) => {
    return state.dashboardRoutes;
  },
  orgLogisticRoutes: (state) => {
    return state.orgLogisticRoutes;
  },
  routesPagination: (state) => {
    return state.orgLogisticRoutesPagination.total_items || 0;
  },
  route: (state) => (id) => {
    if (id) return state.orgLogisticRoutes.find((route) => route.route_id === id);
  },
};

export const actions = {
  async updateRouteDriver({ rootGetters }, { driverId, routeId }) {
    const org_logistics_id = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.patch(
      `/api/fleet/logistics/${org_logistics_id}/routes/${routeId}/driver/${driverId}`,
    );
    return response;
  },

  // Get latest routes(from today onwards) assigned to an OrgLogistic
  async getDashboardLatestRoutes({ commit, rootGetters }) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;

    try {
      const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/dashboard`);
      commit(SET_ORG_LOGISTIC_DASHBOARD_ROUTES, response.data);
    } catch (err) {
      this.$toast.global.customError(`Something went wrong: ${err}`);
    }
  },

  async getOrgLogisticRoutes({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    // Get all the routes assigned to an OrgLogistic
    const org_logistic_id = rootGetters.currentOrgLogisticId;
    try {
      const response = await this.$axios.get(`api/fleet/logistics/${org_logistic_id}/routes`, {
        params: {
          page,
          limit,
        },
      });
      commit(SET_ORG_LOGISTIC_ROUTES, response.data.items);
      commit(SET_ORG_LOGISTIC_ROUTES_PAGINATION, response.data.pagination);
    } catch (error) {
      this.$toast.global.customError(`Something went wrong: ${error}`);
    }
  },
};
